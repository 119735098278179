<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="page-head">
				<div class="page-head__inner">
					<h1 class="page-head__title" style='font-family:"Noto Sans KR","Apple SD Gothic Neo",Helvetica,sans-serif,Arial'>이용약관</h1>
					<!-- <p class="page-head__route">Home<span></span>회사소개</p> -->
				</div>
			</div>

            <div class="container">
                <div class="page-contents">
                    <p v-html="terms.content" class="text-pre-wrap" />
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	data(){
		return {
			terms: {}
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		async init() {
			this.terms = await this.getTerms({ code: "tos" });
		},
		getTerms(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/terms/tos`);

					resolve(res.data.terms);
				}
				catch(error){
					reject(error);
				}
			})
		}
	},
}
</script>
