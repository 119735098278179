var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-contents"
  }, [_c('p', {
    staticClass: "text-pre-wrap",
    domProps: {
      "innerHTML": _vm._s(_vm.terms.content)
    }
  })])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title",
    staticStyle: {
      "font-family": "\"Noto Sans KR\",\"Apple SD Gothic Neo\",Helvetica,sans-serif,Arial"
    }
  }, [_vm._v("이용약관")])])]);

}]

export { render, staticRenderFns }